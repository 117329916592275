import React from 'react'
import HeadingText from './headingText'
import { useNavigate } from 'react-router-dom';
import { BackgroundBeams } from "./background-beams.tsx";
import { useTranslation } from 'react-i18next';
const Headline = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const handleClick = () => {
    navigate('/contactus'); // Replace '/contact' with the route you want to navigate to
  };
  return (
 
    <div className='p-10 bg-darkColor'
    >
  <div
   className='bg-bluebackground flex flex-col rounded-2xl bg-cover  w-full justify-center items-center p-12'
  >
    <h1 className='text-[25px] md:text-[30px] font-bold text-white font-sans text-center'>{t('lets_discuss_your_project')}</h1>
    <h3 className=' font-light text-white font-sans text-center'>{t('free_consultation')}</h3>

    {/* <button className="relative overflow-hidden bg-blue-500 text-white font-bold py-2 px-4 rounded-full transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
      <span className="relative z-10">Contact us</span>
      <span className="absolute top-0 left-0 w-full h-full border border-blue-500 rounded-full animate-ping"></span>
    </button> */}

     <button 
     onClick={handleClick}
     className="relative bg-white text-primary hover:text-white font-bold py-2 px-4 rounded-full transition duration-300 hover:bg-blueColor focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 overflow-hidden mt-10">
      <span className="relative z-10 mx-8 ">{t('contact_us')}</span>
      {/* <span className="absolute top-0 left-0 w-full h-full border-2 border-blue-500 rounded-full "></span> */}
    </button>

  </div>


    </div>
  )
}

export default Headline