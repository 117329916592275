import React from "react";
import { useTranslation } from 'react-i18next';

const CompanyStat = ({ value, label }) => {
  const { t } = useTranslation(); 
  return(
  
  <div className="flex flex-col items-center justify-center text-center  bg-darkColor hover:bg-darkshade rounded-3xl p-6 shadow-md border-darkshade border-[1.5px] hover:border-0 p-4 lg:p-8">
    <div className="text-xl font-bold text-primary sm:text-2xl md:text-3xl">{value}</div>
    <div className="text-sm text-white font-semibold sm:text-base">{t(label)}</div>
  </div>
)};

const CompanyStats = () => {
  const { t } = useTranslation(); 
  const stats = [
    { value: "3+", label: "yearsInBusiness" },
    { value: "25+", label: "solutionsDelivered" },
    { value: "99%", label: "customerRetention" },
    // { value: "Countless", label: "Coffee Cups" },
  ];

  return (
    <div className="bg-darkcolor py-12 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        {/* Text Section */}
        <div className="mb-8 md:mb-12">
          <h2 className="mb-4 text-center text-2xl font-bold text-primary md:mb-6 lg:text-3xl">
          {t('whoWeAreTitle')}
          </h2>
          <p className="mx-auto max-w-screen-md text-center text-white md:text-md">
          {t('whoWeAreDescription')}
          </p>
        </div>

        {/* Stats Section */}
      
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:gap-3">
          {stats.map((stat, index) => (
            <CompanyStat key={index} value={stat.value} label={stat.label} />
          ))}
        </div>
      
      </div>
    </div>
  );
};

export default CompanyStats;
