import React from 'react'
import HeadingText from '../components/headingText'
import SubHeading from '../components/subheading'
import TeamSection from '../components/teammember'
import CompanyStats from '../components/companyStats'
import TrustedBy from '../components/trustBy'
import { useTranslation } from 'react-i18next';

const About = () => {

  const { t } = useTranslation(); 
  return (
    <div className='flex flex-col px-10 pt-20 pb-10 bg-darkColor' >

<HeadingText text={t('about_us')}/>


<div className='h-8'/>
<SubHeading text={"- "+t('aboutUsParagraph1')}/>
 <SubHeading text= {"- "+t('aboutUsParagraph2')}/>  
  <SubHeading text={"- "+t('aboutUsParagraph3')}/> 
  <SubHeading text={"- "+t('aboutUsParagraph4')}/> 

  <CompanyStats/>
  < TrustedBy/>
  {/* <TeamSection/> */}



 
    </div>
  )
}

export default About