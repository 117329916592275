import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import i18n from '../i18n';




const languages = [
  { code: 'en', flag: 'US', name: 'English' },
  { code: 'fi', flag: 'FI', name: 'Suomi' }, // Finnish
  { code: 'de', flag: 'DE', name: 'Deutsch' }, // German
  { code: 'sv', flag: 'SE', name: 'Svenska' }, // Swedish
  { code: 'fr', flag: 'FR', name: 'Français' }, // French
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const dropdownRef = useRef(null); // Ref to track the dropdown container

  // Function to change language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    setIsOpen(false); // Close dropdown after selecting a language
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative ml-2" ref={dropdownRef}>
      {/* Dropdown Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 p-2 rounded-lg bg-white border border-gray-300 hover:bg-gray-100 transition-colors"
      >
        <ReactCountryFlag
          countryCode={languages.find((lang) => lang.code === i18n.language)?.flag || 'US'}
          svg
          className="w-6 h-6"
        />
        <span className="text-sm">
          {languages.find((lang) => lang.code === i18n.language)?.name || 'English'}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute mt-2 w-48 rounded-lg bg-white border border-gray-300 shadow-lg z-10">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className="flex items-center gap-2 p-2 w-full text-left hover:bg-gray-100 transition-colors"
            >
              <ReactCountryFlag
                countryCode={lang.flag}
                svg
                className="w-6 h-6"
              />
              <span className="text-sm">{lang.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;