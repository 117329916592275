import { motion, useAnimation } from "framer-motion";
import Particles, { initParticlesEngine } from "@tsparticles/react"
import { loadAll } from '@tsparticles/all'
import { useEffect, useMemo, useState } from "react";
import particles from "../utils/particles";
// import background from "../asset/images/background3.jpg"
import { fadeIn } from "../data/variants";
import { BackgroundBeams } from "./background-beams.tsx";
import { useTranslation } from 'react-i18next';



const Header = () => {
 
 
const { t } = useTranslation(); 
  const [init, setInit] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadAll(engine);
      //await loadFull(engine);
      // await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };




  return (
  

<div className='relative flex flex-col bg-repeat-round h-screen items-center justify-center overflow-hidden'>
<BackgroundBeams className=" " />
        <div
          className=' bg-darkColor absolute inset-0 z-[-1]  bg-fixed bg-cover '
          // style={{ backgroundImage: `url(${background})` }}
        />
        <motion.div className='relative z-10 text-white'
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount: 0.7}}
        >
        <motion.h1 className='text-4xl md:text-5xl font-extrabold text-center mx-12 font-serif my-2'>
        {t('tagline')}
            <span className="text-blue-500">Softkel.</span>
          </motion.h1>
          <motion.h2 className='text-xl md:text-2xl font-semibold text-center mx-10 my-2'>
            {t('subTagLine')}
          </motion.h2>
        </motion.div>
      </div>
    
    
  )
}

export default Header