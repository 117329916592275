import React from 'react'
import { useTranslation } from 'react-i18next';


const Textfield = (props) => {
   const { t } = useTranslation(); 
  return (
    <div className='flex-col'>
      <h1 className=' text-white text-[20px] mb-3 mt-1 font-semibold'>{props.title}</h1>
      <input className=" text-black w-full p-2 px-4 rounded-sm font-serif placeholder-black" placeholder={t('typeHere')} type={props.type} name={props.name} required/>

    </div>
  )
}

export default Textfield