// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// import en from "./locales/en/translation.json";
// import de from "./locales/de/translation.json";
// import fi from "./locales/fi/translation.json";
// import fr from "./locales/fr/translation.json";
// import sv from "./locales/sv/translation.json";

// i18n.use(initReactI18next).init({
//   resources: {
//     en: { translation: en },
//     de: { translation: de },
//     fi: { translation: fi },
//     fr: { translation: fr },
//     sv: { translation: sv },
//   },
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n; // Ensure this is exported

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"; // Import the language detector

import en from "./locales/en/translation.json";
import de from "./locales/de/translation.json";
import fi from "./locales/fi/translation.json";
import fr from "./locales/fr/translation.json";
import sv from "./locales/sv/translation.json";

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de },
      fi: { translation: fi },
      fr: { translation: fr },
      sv: { translation: sv },
    },
    fallbackLng: "en", // Fallback language if detection fails
    detection: {
      order: ["navigator", "htmlTag"], // Detect language from browser and HTML tag
      caches: ["cookie"], // Cache the detected language in cookies
    },
    interpolation: {
      escapeValue: false, // React already protects against XSS
    },
  });

// Function to detect user's location and set language (IP-based geolocation)
const detectUserLanguage = async () => {
  try {
    // Fetch user's country code using a geolocation API
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    const countryCode = data.country; // e.g., "FI", "DE"

    // Map country code to language
    const languageMap = {
      FI: "fi", // Finland → Finnish
      DE: "de", // Germany → German
      // Add more mappings as needed
    };

    const language = languageMap[countryCode] || "en"; // Default to English if no match
    i18n.changeLanguage(language); // Set the detected language
  } catch (error) {
    console.error("Error detecting user location:", error);
    i18n.changeLanguage("en"); // Fallback to English if detection fails
  }
};

// Call the function to detect language on app load
detectUserLanguage();

export default i18n; // Ensure this is exported