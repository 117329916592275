import { CgWebsite } from 'react-icons/cg';
import {TbDeviceMobileCode} from 'react-icons/tb';
import {IoServerOutline} from 'react-icons/io5'
import {SiSap} from 'react-icons/si'
import { FaPeopleGroup, FaPeopleRoof } from "react-icons/fa6";
import { MdOutlineIntegrationInstructions , MdOutlineAppShortcut   } from "react-icons/md";
import {BsWindowFullscreen} from 'react-icons/bs';
import { VscGraph } from "react-icons/vsc";
import afterSaleApp from "../asset/projectsImages/afterSaleApp1.png"
import dealerApp from "../asset/projectsImages/dealerApp2.png"
import saleApp from "../asset/projectsImages/saleApp1.png"
import webPr from "../asset/projectsImages/webPR1.png"
import warehouse from "../asset/projectsImages/warehouse1.png"
import { RiRobot2Line } from "react-icons/ri";
import { GrTechnology } from "react-icons/gr";

export const Servicess=[
    {
      title: "mobile_web_app_development_title",
      subtitle: "mobile_web_app_development_subtitle",
      icon: CgWebsite,
    },
    {
      title: "consulting_title",
      subtitle: "consulting_subtitle",
      icon: FaPeopleGroup,
    },
    {
      title: "erp_implementation_title",
      subtitle: "erp_implementation_subtitle",
      icon: IoServerOutline,
    },
    {
      title: "sustainability_autonomous_systems_title",
      subtitle: "sustainability_autonomous_systems_subtitle",
      icon: RiRobot2Line,
    },
    {
      title: "digital_transformation_title",
      subtitle: "digital_transformation_subtitle",
      icon: MdOutlineAppShortcut,
    },
    {
      title: "data_analytics_title",
      subtitle: "data_analytics_subtitle",
      icon: VscGraph,
    },
    {
      title: "emerging_technologies_title",
      subtitle: "emerging_technologies_subtitle",
      icon: GrTechnology,
    },
  ];


  export const Projectss = [
    {
      title: "web_pr_title",
      subtitle: "web_pr_subtitle",
      image: webPr,
    },
    {
      title: "dealer_app_title",
      subtitle: "dealer_app_subtitle",
      image: dealerApp,
    },
    {
      title: "after_sale_app_title",
      subtitle: "after_sale_app_subtitle",
      image: afterSaleApp,
    },
    {
      title: "sale_app_title",
      subtitle: "sale_app_subtitle",
      image: saleApp,
    },
    {
      title: "warehouse_management_system_title",
      subtitle: "warehouse_management_system_subtitle",
      image: warehouse,
    },
  ];