import React from 'react'
import HeadingText from '../components/headingText'
import { Servicess,Projectss } from '../data/data'
import SubHeading from '../components/subheading'
import { fadeIn } from "../data/variants";
import { motion } from "framer-motion";
import CompanyStats from '../components/companyStats';
import { useTranslation } from 'react-i18next';


const Project = () => {
  const { t } = useTranslation(); 
  return (
    <div>
      
    <div className='flex flex-col px-5 pt-20 bg-darkColor'>
      <HeadingText text={t('projectHeading')}/>
      <div className='h-6'/>
      <SubHeading text={t("projectSubHeading")}/>
     

      <motion.div 
      // variants={fadeIn("right", 0s.2)}
      // initial="hidden"
      // whileInView={"show"}
      // viewport={{once: false, amount: 0.7}}

      className="grid p-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-4">
      {Projectss.map((project, index) => (
        <motion.div
        // variants={fadeIn("right", 0.2)}
        // initial="hidden"
        // whileInView={"show"}
        // viewport={{once: false, amount: 0.7}}
        
        className=" bg-darkColor hover:bg-darkshade rounded-xl p-3 shadow-md border-darkshade border-[1.5px] hover:border-0" key={index}>
          <div className="text-center">
          <img src={project.image} alt="" className="w-full rounded-lg" />
            <h2 className="text-2xl text-white text-start font-semibold mt-2">{t(project.title)}</h2>
          </div>
          <p className="mt-4 text-gray-600">{t(project.subtitle)}</p>
        </motion.div>
      ))}
    </motion.div >


  

    </div>
    </div>
  )
}

export default Project