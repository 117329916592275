import React from 'react'
import HeadingText from '../components/headingText'
import { Servicess } from '../data/data'
import SubHeading from '../components/subheading'
import { fadeIn } from "../data/variants";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';


const Services = () => {
  const { t } = useTranslation(); 

  return (
    <div className='flex flex-col px-5 pt-20 bg-darkColor'>
      <HeadingText text={t('our_services')}/>
      <div className='h-6'/>
      <SubHeading text={t('service_tagline')}/>
     

      <motion.div 
      // variants={fadeIn("right", 0.2)}
      // initial="hidden"
      // whileInView={"show"}
      // viewport={{once: false, amount: 0.7}}

      className="grid p-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
      {Servicess.map((service, index) => (
        <motion.div
        // variants={fadeIn("right", 0.2)}
        // initial="hidden"
        // whileInView={"show"}
        // viewport={{once: false, amount: 0.7}}
        
        className=" bg-darkColor hover:bg-darkshade rounded-3xl p-6 shadow-md border-darkshade border-[1.5px] hover:border-0" key={index}>
          <div className="text-center">
            <div className="text-4xl text-white mb-2"><service.icon/></div>
            <h2 className="text-xl text-white text-start font-semibold">{t(service.title)}</h2>
          </div>
          <p className="mt-4 text-gray-600">{t(service.subtitle)}</p>
        </motion.div>
      ))}
    </motion.div >


  

    </div>
  )
}

export default Services