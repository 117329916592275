import React from 'react'
import logo from "../asset/images/logo_light.png"
import SubHeading from './subheading'
import {LuMail} from 'react-icons/lu'
import {HiMiniDevicePhoneMobile} from 'react-icons/hi2'
import { useTranslation } from 'react-i18next';



const Footer = () => {
  const { t } = useTranslation(); 
  return (
    <div className=' bg-darkcolor flex flex-col  '>
        <div className='flex md:flex-1 flex-col px-5 py-4'>
        <img src={logo} className=' h-16 w-28' ></img>
        <h1 className=' text-white text-[14px]'>
        {t('softkelDescription')}</h1>
            
        </div>
        <div className=' flex-1 px-5 py-5'>
          <SubHeading text={t('contact_us')+" :"}/>
          <h1 className=' text-white text-[14px]' > {t('contactUs')}</h1>
          <div className='flex flex-row pt-5  items-center'>
            <span className='p-2 items-center bg-primary rounded-xl'><LuMail className='text-white text-[20px] '></LuMail></span>
            <h1 className=' text-white text-[14px] px-2'>info@softkel.com</h1>

          </div>
          <div className='flex flex-row pt-5  items-center'>
            <span className='p-2 items-center bg-primary rounded-xl'><HiMiniDevicePhoneMobile className='text-white text-[20px] '></HiMiniDevicePhoneMobile></span>
            <h1 className=' text-white text-[14px] px-2'>+923244679138</h1>

          </div>

        </div>

    </div>
  )
}

export default Footer